import { ethers } from 'ethers';
import provider from '../web3/provider';
import seedrandom from 'seedrandom';
import EthDater from 'ethereum-block-by-date';

export const ZERO = ethers.constants.Zero;
export const ONE = ethers.constants.One;
export const ADDRESS_ZERO = ethers.constants.AddressZero;
export const MAX_UINT = ethers.constants.MaxUint256;

export const toEther = (bignum) => +ethers.utils.formatEther(bignum);

export const parseEther = (str) => ethers.utils.parseEther(str);

export const formatEther = (bignum, maximumFractionDigits = 2) =>
  toEther(bignum).toLocaleString('en-US', { maximumFractionDigits });

export const range = (start, stop, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const estimateGasLimit = (contract, method, ...args) =>
  contract.estimateGas[method](...args).then((gasLimit) =>
    gasLimit.mul(125).div(100)
  );

export const baseURI =
  process.env.NEXT_PUBLIC_URL ||
  'https://' + process.env.NEXT_PUBLIC_VERCEL_URL;

export const pickRandom = (arr, n = 1, seed) => {
  const rng = seedrandom(seed);
  const result = [];
  const keys = Array.from(arr.keys());

  for (let i = 0; i < n && keys.length > 0; i++) {
    const r = Math.floor(rng() * keys.length);
    result.push(arr[keys[r]]);
    keys.splice(r, 1); // remove key from array
  }

  return result;
};

export const getBlock = async (time) => {
  const dater = new EthDater(provider);

  const drawBlock = await dater.getDate(time, true);

  const block = await provider.getBlock(drawBlock.block);
  return block;
};
