import React from 'react';

const Footer = () => {
  return (
    <footer className="py-3">
      <div className="container d-flex flex-column align-items-center">
        <center>
        <small className="text-muted">
          No Rights Reserved. Noundles artwork is{' '}
          <a
            href="https://creativecommons.org/publicdomain/zero/1.0/"
            target="_blank"
            rel="noreferrer"
            className="link-secondary"
          >
            public domain
          </a>
          .
        </small>
              </center>
      </div>
    </footer>
  );
};

export default Footer;
