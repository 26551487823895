import _ from 'lodash';
import axios from 'axios';
import legacyRaffles from '../raffles.json';
import { baseURI } from './index';

export const fetchWallets = async () => {
  try {
    const wallets = await axios
      .get(baseURI + '/api/wallets')
      .then((res) => _.keyBy(res.data.wallets, 'address'));

    return wallets;
  } catch (e) {
    console.error(e);
  }
};

export const fetchRaffles = async () => {
  try {
    const raffles = await axios
      .get(baseURI + '/api/raffles')
      .then((res) => ({ ...res.data.raffles, ...legacyRaffles }));

    return _.mapValues(raffles, (raffle) => {
      const image = new URL(raffle.image, baseURI).href;
      return { ...raffle, image };
    });
  } catch (e) {
    console.error(e);
  }
};

const api = {
  fetchWallets,
  fetchRaffles,
};

export default api;
