import React from 'react';
import Link from 'next/link';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from "next/legacy/image";

import ConnectButton from './ConnectButton';

const Header = () => {
  return (
    <Navbar expand="lg" style={{ backgroundColor: '#FAE98E' }}>
      <div className="container">
        <Link href="/" passHref legacyBehavior>
          <Navbar.Brand className="d-flex align-items-center">
            <Image src="/pfp2.png" width={48} height={48} alt="Noundles Logo" />
            <span className="ps-1 d-none d-md-block">Noundles</span>
          </Navbar.Brand>
        </Link>
        <div className="ms-auto order-lg-1">
          <ConnectButton autoconnect />
        </div>
        <Navbar.Toggle aria-controls="navbar-collapse" className="ms-3" />
        <Navbar.Collapse id="navbar-collapse">
          <Nav>
            <Nav.Item>
              <Link href="/" passHref legacyBehavior>
                <Nav.Link>Home</Nav.Link>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link href="/dashboard" passHref legacyBehavior>
                <Nav.Link>Dashboard</Nav.Link>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link href="/shop" passHref legacyBehavior>
                <Nav.Link>Rainbow Shop</Nav.Link>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://my-store-cc4d38.creator-spring.com/">
                Merch
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Link href="/about" passHref legacyBehavior>
                <Nav.Link>About</Nav.Link>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link href="/team" passHref legacyBehavior>
                <Nav.Link>Team</Nav.Link>
              </Link>
            </Nav.Item>
          </Nav>
          <Nav className="ms-auto me-3 flex-row">
            <Nav.Item>
              <Nav.Link
                href="https://twitter.com/TheNoundles"
                target="_blank"
                className="d-flex px-1"
              >
                <Image
                  src="/twitter-circle.svg"
                  alt="Twitter"
                  width={32}
                  height={32}
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://opensea.io/collection/noundles"
                target="_blank"
                className="d-flex px-1"
              >
                <Image
                  src="/opensea-circle.svg"
                  alt="OpenSea"
                  width={32}
                  height={32}
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://discord.gg/noundles"
                target="_blank"
                className="d-flex px-1"
              >
                <Image
                  src="/discord-circle.svg"
                  alt="Discord"
                  width={32}
                  height={32}
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://instagram.com/noundlesnft"
                target="_blank"
                className="d-flex px-1"
              >
                <Image
                  src="/instagram-circle.svg"
                  alt="Instagram"
                  width={32}
                  height={32}
                />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
