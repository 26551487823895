import { CRMProvider } from './CRMContext';
import { RaffleProvider } from './RaffleContext';

export const ContextProviders = ({ children }) => {
  return (
    <CRMProvider>
      <RaffleProvider>{children}</RaffleProvider>
    </CRMProvider>
  );
};
