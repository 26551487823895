import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';
import { Toaster } from 'react-hot-toast';
import Layout from '../components/Layout';

import * as ga from '../lib/ga';

// import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/globals.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ContextProviders } from '../context';

const queryClient = new QueryClient();

function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider);
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const pageview = (url) => {
      ga.pageview(url);
    };

    router.events.on('routeChangeComplete', pageview);

    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router]);

  return (
    <>
      <Head>
        <title>Noundles</title>
        <meta
          name="description"
          content="Entertaining & inspiring people around the world through the power of collaboration, innovation and transparency."
          key="description"
        />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.noundles.io" />
        <meta property="og:title" content="Noundles" key="og:title" />
        <meta
          property="og:description"
          content="Entertaining & inspiring people around the world through the power of collaboration, innovation and transparency."
          key="og:description"
        />
        <meta
          property="og:image"
          content="https://www.noundles.io/banner-grid.png"
          key="og:image"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.noundles.io" />
        <meta property="twitter:title" content="Noundles" key="twitter:title" />
        <meta
          property="twitter:description"
          content="Entertaining & inspiring people around the world through the power of collaboration, innovation and transparency."
          key="twitter:description"
        />
        <meta
          property="twitter:image"
          content="https://www.noundles.io/banner-grid.png"
          key="twitter:image"
        />
      </Head>

      <Web3ReactProvider getLibrary={getLibrary}>
        <QueryClientProvider client={queryClient}>
          <ContextProviders>
            <Layout>
              <Component {...pageProps} />
              <Toaster />
            </Layout>
          </ContextProviders>
        </QueryClientProvider>
      </Web3ReactProvider>
    </>
  );
}

export default MyApp;
