import React, { useEffect, useState } from 'react';
import { fetchRaffles } from '../util/api';

const RaffleContext = React.createContext({});

export const RaffleProvider = ({ children }) => {
  const [raffles, setRaffles] = useState({});

  useEffect(() => {
    fetchRaffles().then((raffles) => setRaffles(raffles));
  }, []);

  return (
    <RaffleContext.Provider value={raffles}>{children}</RaffleContext.Provider>
  );
};

export default RaffleContext;
