import React, { useEffect, useState } from 'react';
import { fetchWallets } from '../util/api';

const CRMContext = React.createContext({});

export const CRMProvider = ({ children }) => {
  const [wallets, setWallets] = useState({});

  useEffect(() => {
    fetchWallets().then((wallets) => setWallets(wallets));
  }, []);

  return <CRMContext.Provider value={wallets}>{children}</CRMContext.Provider>;
};

export default CRMContext;
